<template>
  <router-view />
</template>

<style lang="scss">
  @import "./main.scss";

  body {
    background: linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url("./assets/16861702_5803427.svg");
    background-size: cover;
    background-position: center;
  }
</style>
